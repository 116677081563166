<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog_from_input"
      width="950"
      @click:outside="close()"
      @keydown.esc="close()"
      scrollable
    >

      <v-card
      tile
      >
        <v-card-title
          class="custom-modal-title"
          :style="backgroundColor"
          primary-title
        >
          {{edit_data ? 'Edit' : 'Create'}} Customer
            <v-spacer></v-spacer>
          <span @click="close" style="cursor:pointer">x</span>
        </v-card-title>

        <v-card-text>
          <v-container>
               <v-form
                    ref="form"
                    v-model="valid"
                    :lazy-validation="lazy"
                    >
                    <v-row>
                      <v-col cols="12" md="4" class="text-center">
                        <v-img style="cursor:pointer" :src="img" @click="$refs.foto_profile.click()"></v-img>
                        <span style="color:red">Image max 300Kb</span>
                      </v-col>
                      <v-col cols="12" md="8">
                          <v-text-field
                          v-model="name"
                          label="Name"
                          required
                          :rules="nameRules"
                          ></v-text-field>
                          <v-text-field
                          v-model="phone"
                          :rules="phoneRules"
                          label="Phone"
                          required
                          ></v-text-field>
                          <v-text-field
                          v-model="email"
                          :rules="emailRules"
                          label="Email"
                          ></v-text-field>

                          <v-textarea
                            v-model="address"
                            label="Address"
                          ></v-textarea>
                          <v-textarea
                            v-model="note"
                            label="Note"
                          ></v-textarea>
                      </v-col>
                    </v-row>
               </v-form>
               <input type="file" style="display:none; " id="foto_profile" ref="foto_profile" accept="image/*" @change="eventChange">
          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            tile
            class="custom-modal-title"
            @click="close"
          >
            Cancel
          </v-btn>

           <v-btn
            color="success"
            tile
            :disabled="!valid"
            @click="edit_data ? update() : save()"
            :loading="loading"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import GlobalMixin from '../../../mixin/index'
export default {
  mixins:[GlobalMixin],
  methods: {
    send_data() {
      const data = new FormData()
      data.append('phone', this.phone)
      data.append('photos', this.foto)
      data.append('email', this.email)
      data.append('address', this.address)
      data.append('note', this.note)
      data.append('name', this.name)
      data.append('entry_by', this.user.fullname)
      data.append('partner_type', "CUSTOMER")
      data.append('company_id', this.user.company_id)
      return data
    },
    async save () {
      this.loading = true
      const data = this.send_data()
      await this.axios.post('v1/partner/create', data)
        .then(async (ress) => {
          this.setSnakbar({
            status: true,
            pesan: 'Success submit data',
            color_snakbar: 'success'
          })
          await this.go('customer','customers')
          this.close()
        })
        .catch((err) => console.log(err.response))
      this.loading = false
    },

    async update() {
      this.loading = true
      const data = this.send_data()
      await this.axios.put('v1/partner/update/' + this.edit_data.id, data)
        .then(async (ress) => {
          this.setSnakbar({
            status: true,
            pesan: 'Success update data',
            color_snakbar: 'success'
          })
          await this.go('customer','customers')
          this.close()
        })
        .catch((err) => console.log(err.response))
      this.loading = false
    },
    close () {
      this.reset()
      this.$emit('close')
    },
    reset () {
      this.phone = ''
      this.foto = ''
      this.email = ''
      this.address = ''
      this.note = ''
      this.name = ''
      this.imgurl = 'img/no_img.png'
    }
  },

  data: () => ({
    valid: true,
    lazy: false,
    loading: false,
    name: '',
    note: '',
    phone: '',
    email: '',
    address: '',
    password: '',
    phoneRules: [
      (v) => !v || /^(^\+62\s?|^0)(\d{3,4}-?){2}\d{3,4}$/g.test(v) || 'Please enter a valid Phone number (ex. 0857XXXXXX)'
    ],
    emailRules: [
      v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid'
    ],
    passwordConfirm: '',
    nameRules: [
      v => !!v || 'Field is required'
    ],
    passwordConfirmRules (password) {
      return v => v === password || 'Harus sama'
    },
    imgurl: 'img/no_img.png',
    foto: null

  }),

  computed: {
    img: function () {
      if (this.edit_data) {
        if (this.edit_data.image_link) {
          return process.env.VUE_APP_IMGLINK + 'customer/' + this.edit_data.image_link
        } else {
          return this.imgurl
        }
      } else {
        return this.imgurl
      }
    }
  },

  created () {
    if (this.edit_data) {
       this.phone = this.edit_data.phone
          this.email = this.edit_data.email
          this.address = this.edit_data.address
          this.note = this.edit_data.note
          this.name = this.edit_data.name
    }
  }
}
</script>
